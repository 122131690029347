import styled from 'styled-components';

const BaseButton = styled.button`
  border-radius: 18px;
  border: none;
  box-shadow: 0px 0px 10px 0px #00000026;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  height: 40px;
  padding: 0px 20px;
  text-transform: uppercase;

  &:hover {
    background-color: #020c90;
    color: #ffffff;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  background-color: #ffcb29;
`;

export const OutlineButton = styled(BaseButton)`
  background-color: transparent;
  border: 0.5px solid #9a9a9a;
  color: #9a9a9a;
  box-shadow: none;

  &:visited {
    color: #9a9a9a;
    border-color: #9a9a9a;
  }

  &:hover {
    border: none;
  }

  .selected {
    color: #020c90;
    border-color: #020c90;
  }
`;

export const DisabledButton = styled(BaseButton)`
  background-color: #dadada;
  cursor: initial;

  &:hover {
    background-color: #dadada;
    color: #ffffff;
  }
`;

export const SecondaryButton = styled(BaseButton)`
  min-width: 118px;
  background-color: transparent;
  border: 0.5px solid #ffcb29;
  color: #ffcb29;

  &:visited {
    color: #9a9a9a;
    border-color: #9a9a9a;
  }

  &:hover {
    border: none;
  }

  .selected {
    color: #020c90;
    border-color: #020c90;
  }
`;
